@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

*{
    font-family: 'Poppins', sans-serif;
    background: linear-gradient(to right, #f5f7fa, #f0f6ff);    
    color: #2c115e;
}
* h1{
    display: flex;
    justify-content: center;
    font-weight: bold;
    font-size:xx-large;
}
* a{
    text-decoration: none;
    color: #2c115e;
}

@media (max-width: 768px){
    /* .nav ul{
        display: none;
    }
    .login a{
        display: none;
    } */

    .nav ul{
        display: table-row;
        margin-left: 3rem;
    }
    .login{
        margin-left: 4.5rem;
    }
}

.nav{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    border-bottom: 1px solid #80689d;
}
.brand{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.brand img{
    width: 150px;
}
.items{
    display: flex;
    padding-top: 1rem;
    list-style: none;
}
.items li{
    display: flex;
    margin : 0 1rem;
    cursor: pointer;
}
.items li a{
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #2c115e;
}

.items li:hover{
    font-weight:bold;
}
.login{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.login a{
    margin: 0 1.5rem;
    text-decoration: none;
    transition: 0.5s ease;
    cursor: pointer;
    color: #2c115e;
}
.login a:hover{
    border-bottom:1px solid #80689d;
}


.slide{
    padding-top: 1rem;
    padding-bottom: 2.5rem;
}
.slide img{
    border-radius: 2.5rem;
    width: 100%;
}

.row1{
    justify-content: center;
    margin-top: 3rem;
    text-align: center;
}
.row2{
    justify-content: center;
    margin-top: 3rem;
    text-align: center;
}

.row3{
    justify-content: center;
    margin-top: 3rem;
    text-align: center;
}

.card{
    box-shadow: 6px 6px 9px #80689d;
    margin: 2rem;
    border-radius: 10px;
    width: 20%;
    min-width: 250px;
    transition: transform .4s;
}
.card:hover{
    transform: scale(1.03);
}
.card h4{
    font-weight: 350;
    text-align: center;
}
.card button{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    color: #80689d;
    border-color: #80689d;
    border-radius: 0.5rem;
    margin: 0.5rem;
}
.card button a{
    display: flex;
    justify-content: center;
    align-items: center;
}
.card button a:hover{
    border-color: #2c115e;
    color : #2c115e;
    font-weight: bold;
}
.card img{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 100%;
    border-radius: 20px;
}

.down{
    text-align: center;
}
.down button{
    border: none;
}

.contents h1{
    margin: 2rem;
}
.footer{
    position: fixed;
    border-top: 1px solid #2c115e;
    text-align: center;
}
.footer p{
    float: right;
    margin: 1rem;
    font-size: 14px;
}

/* Salon for Women */

.salonrow{
    min-width: 350px;
    display: flex;
    margin: 1.5rem;
    box-shadow: 6px 6px 9px #80689d;
    border-radius: 2rem;
}
.saloncol{
    border-radius: 2rem;
    padding : 1rem;
    align-items: center;
}
.saloncol img{
    min-width: 250px;
    border-radius: 1rem;
}
.saloncol button{
    border-radius: 1rem;
}


/* Login */

.loginform{
    margin: 0rem;
    display:grid;
    gap:1rem;
    margin-top: 10rem;
    justify-content: center;
    align-items: center;
}
.loginform input{
    display: flex;
    position: relative;
    border-radius: 1rem;
    min-width: 300px;
}
.loginform h1{
    display: flex;
    justify-content: center;
    align-items: center;
}
.loginform p{
    display: flex;
    justify-content: center;
    align-items: center;
}
.loginform a{
    color: #80689d;
}
.loginform a:hover{
    color: #2c115e;
    font-weight: bold;
}
.btn a{
    font-weight: bold;
}

/* Professional */

.forms{
    display: flex;
    justify-content: center;
    align-items: center;
}
.forms label{
    display: block;
    margin: 0 1rem;
    padding: 1rem;
}
.forms input{
    display: flex;
    position: relative;
    max-width: fit-content;
    border-radius: 1rem;
}
.btn{
    border: none;
}
.btn a{
    margin: 0 1rem;
    padding: 0 1rem;
    border: 1px solid #2c115e;
    border-radius: 1rem;
    display:flow-root;
    color: #80689d;
}
.btn a:hover{
    color: #2c115e;
}
